import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import React, { Component } from 'react'
import onClickOutside from 'react-onclickoutside'
import { scroller } from 'react-scroll'
import styled from 'styled-components'
import Theme from 'mgr/layout/Theme'
import { validatorFuncs, ValidatorTypes } from 'mgr/lib/forms/TextInput'
import { VmsIcons, StyledVmsIconS } from 'svr/common/VmsIcons'
import Caret from 'svr/component-lib/Generic/Symbols/Caret'

const isDarkTheme = props => props.customStyle && props.customStyle.widgetTemplateTheme.toLowerCase() === 'dark'

const computeBorderedAreaStyles = props => {
  const styles = {}

  if (isDarkTheme(props)) {
    styles.borderWidth = 2
  } else {
    styles.borderWidth = 1
  }

  if (props.isValid) {
    if (isDarkTheme(props)) {
      styles.borderColor = props.customStyle.colorLines
    } else {
      styles.borderColor = props.borderColor
    }
  } else {
    styles.borderColor = props.theme.error
  }

  if (props.disabled) {
    styles.backgroundColor = !_.isEmpty(props.theme) ? props.theme.color.white : '#ffffff'
    if (props.customStyle) {
      styles.hoverBorderColor = props.customStyle.colorLines
    } else {
      styles.hoverBorderColor = props.borderColor
    }
  } else if (isDarkTheme(props)) {
    styles.backgroundColor = props.customStyle.colorCellBackground
  } else {
    styles.backgroundColor = !_.isEmpty(props.customStyle) ? props.customStyle.colorCellBackground : '#ffffff'
    styles.hoverBorderColor = props.hoverBorderColor
  }

  return styles
}

const computeOptionDarkThemeStyles = props => {
  const styles = {}
  if (props.isSelectedChoice) {
    styles.color = props.theme.white
    if (props.customStyle) {
      styles.backgroundColor = props.customStyle.colorPrimary
    } else {
      styles.backgroundColor = '#031927'
    }
  } else if (props.customStyle) {
    styles.color = props.customStyle.fontsColorPrimary
    if (props.customStyle.isResWidgetDark) {
      styles.backgroundColor = '#8a8b8b'
    } else {
      styles.backgroundColor = props.customStyle.colorCellBackground
    }
  } else {
    styles.backgroundColor = !_.isEmpty(props.customStyle) ? props.customStyle.colorCellBackground : '#ffffff'
    styles.color = props.theme.navigationDark
  }

  if (isDarkTheme(props)) {
    styles.borderColor = props.customStyle.colorLines
  } else {
    styles.borderColor = props.theme.navigationDark
  }

  return styles
}

const computeSelectedItemDisplayStyles = props => {
  const styles = {}

  if (props.hasSelectedValue && !props.disabled) {
    if (props.customStyle) {
      styles.color = props.customStyle.fontsColorPrimary
    } else {
      styles.color = props.theme.navigationDark
    }
  } else {
    styles.color = props.theme.darkGrey
  }

  return styles
}

const OuterBody = styled.div`
  min-width: 154px;
  width: 154px;
  min-height: ${props => props.height}px;
  margin-right: ${props => (props.marginRight ? props.marginRight : '9px')};
  ${props => props.theme.clearFix};
  ${props => props.style};
`

const BorderedArea = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  min-height: ${props => props.height + 2}px;
  height: ${props => props.height + 2}px;
  position: relative;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  border: ${props => computeBorderedAreaStyles(props).borderWidth}px solid ${props => computeBorderedAreaStyles(props).borderColor};
  border-radius: 4px;
  background-color: ${props => computeBorderedAreaStyles(props).backgroundColor};
  box-shadow: ${props => (props.isOpen ? '0 2px 4px 0 rgba(0,0,0,0.5)' : 'none')};
  :hover {
    border-color: ${props => computeBorderedAreaStyles(props).hoverBorderColor};
    transition: border-color 0.2s ease-out;
  }
  ${props => props.theme.clearFix};
`

// Fix for #3 here: https://github.com/philipwalton/flexbugs
// Since BorderedArea has min-height: 44px
const BorderedAreaFlexIEFix = styled.div`
  order: 0;
  width: 0;
  height: ${props => props.height + 2}px;
`

const NameOutside = styled.div`
  color: ${props => (props.isValid ? props.theme.darkGrey : props.theme.error)};
  ${props => props.theme.fontWeight300} font-size: 11px;
  padding: 0 0 4px 10px;
  ${props => props.labelStyle};
`

const NameInside = styled.div`
  position: absolute;
  top: 3px;
  left: 10px;
  right: 3px;
  color: ${props => props.theme.darkGrey};
  font-weight: 500;
  font-size: ${props => (props.isLightTheme ? '11px' : '12px')};
`

const Icon = styled(StyledVmsIconS)`
  order: 2;
  font-size: 17px;
  width: 17px;
  height: 17px;
  pointer-events: none;
  margin: 0 10px 0 5px;
`

const DropdownRelative = styled.div`
  position: relative;
  width: 100%;
`

const DropdownAbsolute = styled.div`
  position: absolute;
  width: 100%;
`

const DropUpAbsolute = styled(DropdownAbsolute)`
  bottom: 0;
`

const OptionGroupTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #848d94;
  padding: 5px 10px;
  border-top: 1px solid #848d94;
  text-transform: uppercase;
  ${props => props.theme.ellipsis};
`

const OnlyButton = styled.div`
  display: none;
  color: ${props => props.theme.darkGrey};
  box-sizing: border-box;
  margin: -10px 0; // Full height of cell
  padding: 10px 0 10px 5px;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`

const OptionBase = styled.div`
  height: 37px;
  overflow: hidden;
  font-size: 14px;
  align-items: baseline;
  padding: 10px;
  box-sizing: border-box !important;
  display: flex;
  :hover ${OnlyButton} {
    display: block;
  }
`

const OptionLightTheme = styled(OptionBase)`
  color: ${props => props.theme.navigationDark};
  background-color: ${props => (props.isSelectedChoice ? props.theme.primaryRgba20 : props.theme.background)};
  :hover,
  :focus {
    background-color: ${props => props.theme.primaryRgba10};
  }
`

const OptionDarkTheme = styled(OptionBase)`
  color: ${props => computeOptionDarkThemeStyles(props).color};
  background-color: ${props => computeOptionDarkThemeStyles(props).backgroundColor};
  border-bottom: ${props => (props.isLast ? '0px' : '1px')} solid ${props => computeOptionDarkThemeStyles(props).borderColor};
  :hover,
  :focus {
    color: #031927;
    background-color: ${props => props.theme.lightGrey};
  }
`

const OptionText = styled.span`
  flex-shrink: 50;
  ${props => props.theme.ellipsis};
`

const SelectedItemDisplay = styled.div`
  order: 1;
  flex-grow: 1;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin-left: 10px;
  margin-top: ${props => (props.useOutsideLabel || props.noHeader ? '0' : '15px')};
  color: ${props => computeSelectedItemDisplayStyles(props).color};
  ${props => props.theme.ellipsis};
`

const OptionsContainer = styled.div`
  position: relative;
  border: 1px solid ${props => (props.customStyle ? props.customStyle.colorLines : props.borderColor)};
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  top: -2px;
  left: 0;
  width: 100%;
  max-height: 332px;
  overflow-y: auto;
  box-sizing: border-box;
  cursor: default;
  user-select: none;
  z-index: 501;
  border-radius: 0 0 4px 4px;
`

const Navigation = styled.div`
  order: 4;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  color: ${props => (props.disabled ? props.theme.darkGrey : props.theme.navigationDark)};
  border-radius: 0 4px 4px 0;
  height: 100%;
  box-sizing: border-box;
  width: 80px;
`

const Extra = styled.div`
  order: 3;
`

const NavigationCell = styled(StyledVmsIconS)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 100;
  font-size: 20px;
  box-sizing: border-box;
  width: 50%;

  :hover {
    background-color: ${props => (props.disabled ? 'transparent' : props.theme.lightGrey)};
  }
`

const MoveLeft = styled(NavigationCell)`
  transform: rotate(180deg);
  border-right: 1px solid ${props => props.borderColor};
`

const MoveRight = styled(NavigationCell)`
  border-left: 1px solid ${props => props.borderColor};
`

const DropDown = styled.div`
  width: 250px;
  top: 5px;
  left: -1px;
  position: relative;
  z-index: 501;
`

const requiredValidators = Object.freeze([ValidatorTypes.notEmpty, ValidatorTypes.cardMonthExp, ValidatorTypes.cardYearExp])

const callValidator = (value, vtype) => validatorFuncs[vtype](value)

export class DropdownArrowsPicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
    this.didScrollOnOpen = false
    this.handleMoveLeft = this.handleMoveLeft.bind(this)
    this.handleMoveRight = this.handleMoveRight.bind(this)
    this.handleItemClick = this.handleItemClick.bind(this)
    this.handleItemOnlyClick = this.handleItemOnlyClick.bind(this)
    this.handleToggleDropdownClick = this.handleToggleDropdownClick.bind(this)
    this.isValid = this.isValid.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleKeyPressOption = this.handleKeyPressOption.bind(this)
  }

  componentWillMount() {
    const firstSelectedElementId = _.uniqueId('firstSelectedElement-')
    this.setState({ firstSelectedElementId })
  }

  componentDidUpdate() {
    const { isMultiSelect } = this.props
    const { isOpen, firstSelectedElementId } = this.state
    const hasSelectedElement = !!document.getElementById(firstSelectedElementId)
    if (!isMultiSelect && isOpen && !this.didScrollOnOpen && hasSelectedElement) {
      scroller.scrollTo(firstSelectedElementId, {
        duration: 200,
        offset: 0,
        smooth: 'easeOutCubic',
        containerId: 'optionsContainer',
      })
    }
    this.didScrollOnOpen = isOpen
  }

  /**
   * Returns true if valid, otherwise invalid text
   */
  isValid() {
    const { validator, customValidator, value } = this.props
    const customIsValid = customValidator(value)

    if (customIsValid !== true) {
      return customIsValid
    }
    if (_.isEmpty(validator)) {
      return true
    }

    return callValidator(value || '', validator) || this.buildInvalidDisplayText()
  }

  handleKeyPress(event) {
    if (event.key === 'Escape' || event.key === 'Esc') {
      this.setState({ isOpen: false })
      return
    }

    if (event.key === 'Enter' || event.key === ' ') {
      this.setState({ isOpen: !this.state.isOpen })
    }
  }

  handleKeyPressOption(event, choice) {
    if (event.key === 'Escape' || event.key === 'Esc') {
      this.setState({ isOpen: false })
      return
    }

    if (event.key === 'Enter' || event.key === ' ') {
      this.handleItemClick(event, choice)
    }
  }

  buildInvalidDisplayText() {
    const { name, placeholder, invalidDisplayText, validator, value } = this.props
    if (!_.isEmpty(invalidDisplayText)) {
      return invalidDisplayText
    }
    const isRequiredValidator = _.includes(requiredValidators, validator)
    const showRequiredMsg = (isRequiredValidator && !value) || _.isEmpty(value)
    return _.startCase(_.toLower(name || placeholder)) + (showRequiredMsg ? ' is required' : '  is not valid')
  }

  closeDropdown() {
    if (this.state.isOpen) {
      this.setState({ isOpen: false })
    }
  }

  handleClickOutside() {
    if (this.state.isOpen) {
      this.setState({ isOpen: false })
    }
  }

  handleToggleDropdownClick(event) {
    if (event.target.closest('.dropdown-arrows-picker-extra, [role="dialog"]')) {
      return
    }
    const { disabled } = this.props
    if (disabled) {
      return
    }
    this.setState({ isOpen: !this.state.isOpen })
  }

  handleMoveLeftOrRight(choice, onMove) {
    const { onChangeHandler, disabled } = this.props
    if (disabled) {
      return
    }
    this.setState({ isOpen: false })
    let selectValue
    if (_.isString(choice) || _.isNumber(choice)) {
      selectValue = choice
    } else if (choice.hasOwnProperty('selectValue')) {
      selectValue = choice.selectValue
    } else {
      selectValue = choice.value
    }
    onMove(selectValue)
    onChangeHandler(selectValue)
  }

  handleMoveLeft(e) {
    const { choices, value, onMoveLeft } = this.props
    let { moveLeftValue } = this.props
    e.stopPropagation()

    if (_.isNil(moveLeftValue)) {
      let index = _.findIndex(choices, { value }) - 1
      if (index < 0) {
        index = choices.length - 1
      }
      moveLeftValue = choices[index]
    }
    this.handleMoveLeftOrRight(moveLeftValue, onMoveLeft)
  }

  handleMoveRight(e) {
    const { choices, value, onMoveRight } = this.props
    let { moveRightValue } = this.props
    e.stopPropagation()

    if (_.isNil(moveRightValue)) {
      let index = _.findIndex(choices, { value }) + 1
      if (index === choices.length) {
        index = 0
      }
      moveRightValue = choices[index]
    }
    this.handleMoveLeftOrRight(moveRightValue, onMoveRight)
    e.stopPropagation()
  }

  handleItemClick(e, choice) {
    const { onChangeHandler, disabled, isMultiSelect, values } = this.props
    e.stopPropagation()
    if (disabled) {
      return
    }

    this.setState({ isOpen: isMultiSelect })
    if (isMultiSelect) {
      if (_.includes(values, choice.value)) {
        onChangeHandler(_.difference(values, [choice.value]), 'removed', choice.value)
      } else {
        onChangeHandler(_.union(values, [choice.value]), 'added', choice.value)
      }
      return
    }
    if (choice.hasOwnProperty('selectValue')) {
      onChangeHandler(choice.selectValue)
    } else {
      onChangeHandler(choice.value)
    }
  }

  handleItemOnlyClick(e, choice) {
    const { onChangeHandler, disabled, isMultiSelect } = this.props
    e.stopPropagation()
    if (disabled || !isMultiSelect) {
      return
    }

    onChangeHandler([choice.value], 'added', choice.value)
  }

  render() {
    const {
      testId,
      showCaret,
      IconCaret,
      dontShowSelectedItem,
      name,
      value,
      values,
      displayOverride,
      adtlDisplayText,
      dropDown,
      isLightTheme,
      isMultiSelect,
      isValid,
      placeholder,
      icon,
      leftSideIcon,
      showNavigation,
      useOutsideLabel,
      isDropUp,
      style,
      borderAreaStyle,
      disabled,
      optionsContainerStyle,
      height,
      marginRight,
      noHeader,
      labelStyle,
      wrapText,
      id,
      ariaDescriptor,
      customStyle,
      selectedItemDisplayStyle,
      optionBaseStyle,
      dropdownRelativeStyle,
      extra,
    } = this.props
    const { isOpen, firstSelectedElementId } = this.state
    const hoverBorderColor = isLightTheme ? Theme.darkGrey : Theme.black
    const borderColor = isOpen ? hoverBorderColor : isLightTheme ? Theme.lightGrey : '#031927'

    const OptionTheme = isLightTheme ? OptionLightTheme : OptionDarkTheme
    const selectedItemNames = []

    const renderSingleOption = (choice, isLast, isSelectedChoice, optionId) => {
      const icon = choice.icon && <StyledVmsIconS style={choice.iconStyle}>{choice.icon}</StyledVmsIconS>
      const checkbox = isMultiSelect && (
        <div>
          <input type="checkbox" checked={isSelectedChoice} readOnly />
          &nbsp;&nbsp;
        </div>
      )
      const onlyButton = isMultiSelect && choice.value.toString().toLowerCase() !== 'all' && (
        <OnlyButton onClick={e => this.handleItemOnlyClick(e, choice)}>only</OnlyButton>
      )

      let optionStyle = null
      if (!wrapText) {
        // eslint-disable-next-line no-param-reassign
        choice.style = _.extend(choice.style, { height: 'auto' })
        optionStyle = { 'white-space': 'normal' }
      }

      return (
        <OptionTheme
          onKeyDown={e => this.handleKeyPressOption(e, choice)}
          tabIndex="0"
          aria-label={choice.name}
          style={{ ...choice.style, ...optionBaseStyle }}
          key={choice.value}
          id={optionId}
          {...{ isLast, isSelectedChoice, customStyle }}
          onClick={e => this.handleItemClick(e, choice)}
          data-test={`sr-picker-option-${choice.value}`}
        >
          {icon}
          {checkbox}
          <OptionText style={optionStyle}>{choice.name}</OptionText>
          {onlyButton}
        </OptionTheme>
      )
    }

    const renderOptionGroup = optionGroup => (
      <div key={optionGroup.name}>
        <OptionGroupTitle>{optionGroup.name}</OptionGroupTitle>
        {renderOptions(optionGroup.choices)}
      </div>
    )

    let leftSideIconElement = null
    const renderOptions = choices =>
      choices.map((choice, idx) => {
        if (choice.hasOwnProperty('choices')) {
          // This is an option group
          return renderOptionGroup(choice)
        }
        const isLast = idx === choices.length - 1
        const isSelectedChoice = choice.value === value || _.includes(values, choice.value)
        if (name === 'waitTimeBuffer') {
          // eslint-disable-next-line no-console
          console.log(`Choice: ${choice.value}, Value: ${value}, Type choice: ${typeof value}`)
        }
        let optionId = null
        if (isSelectedChoice) {
          if (_.isEmpty(selectedItemNames)) {
            optionId = firstSelectedElementId
          }
          selectedItemNames.push(choice.name)

          if (choice.hasOwnProperty('icon')) {
            leftSideIconElement = <StyledVmsIconS style={choice.iconStyle}>{choice.icon}</StyledVmsIconS>
          }
        }
        return renderSingleOption(choice, isLast, isSelectedChoice, optionId)
      })

    if (!leftSideIconElement && leftSideIcon) {
      leftSideIconElement = <Icon>{leftSideIcon}</Icon>
    }

    const renderedOptions = renderOptions(this.props.choices)
    const hasSelectedValue = !_.isEmpty(selectedItemNames) || displayOverride
    let selectedItemName = _.isNil(displayOverride) ? selectedItemNames.join(', ') : displayOverride
    selectedItemName += !_.isNil(adtlDisplayText) && value !== 0 ? adtlDisplayText : ''
    const optionsContainer = (
      <OptionsContainer
        id="optionsContainer"
        data-test="sr-picker_options"
        key="optionsContainer"
        style={optionsContainerStyle}
        ariaExpanded={isOpen}
        role="list"
        {...{ borderColor, customStyle }}
      >
        {renderedOptions}
      </OptionsContainer>
    )

    const navigation = (
      <Navigation {...{ disabled }}>
        <MoveLeft data-test="sr-picker_control-left" key="moveLeft" {...{ disabled, borderColor }} onClick={this.handleMoveLeft}>
          {VmsIcons.Chevron}
        </MoveLeft>
        <MoveRight data-test="sr-picker_control-right" key="moveRight" {...{ disabled, borderColor }} onClick={this.handleMoveRight}>
          {VmsIcons.Chevron}
        </MoveRight>
      </Navigation>
    )

    const DropContainer = isDropUp ? DropUpAbsolute : DropdownAbsolute

    return (
      <OuterBody
        data-test={testId || (name ? `sr-picker-${name.toLowerCase().replace(/ /g, '_')}` : null)}
        aria-label={ariaDescriptor}
        onKeyDown={this.handleKeyPress}
        role="button"
        tabIndex="0"
        id={id}
        marginRight={marginRight}
        {...{ style, height }}
      >
        {useOutsideLabel && !noHeader && <NameOutside {...{ isValid, labelStyle }}>{name}</NameOutside>}
        <BorderedArea
          {...{
            height,
            borderColor,
            hoverBorderColor,
            useOutsideLabel,
            isOpen,
            isValid,
            disabled,
            customStyle,
          }}
          style={borderAreaStyle}
          onClick={this.handleToggleDropdownClick}
        >
          <BorderedAreaFlexIEFix {...{ height }} />
          {!useOutsideLabel && !noHeader && <NameInside {...{ isLightTheme }}>{name}</NameInside>}
          {!dontShowSelectedItem && (
            <SelectedItemDisplay
              {...{
                useOutsideLabel,
                noHeader,
                hasSelectedValue,
                disabled,
                customStyle,
              }}
              style={selectedItemDisplayStyle}
            >
              {leftSideIconElement} {selectedItemName || placeholder}
            </SelectedItemDisplay>
          )}
          {showNavigation && !isMultiSelect ? navigation : showCaret && !disabled && <IconCaret customStyle={customStyle} />}
          {icon && <Icon>{icon}</Icon>}
          {extra && <Extra className="dropdown-arrows-picker-extra">{extra}</Extra>}
        </BorderedArea>
        <DropdownRelative style={dropdownRelativeStyle}>
          <DropContainer>
            {isOpen && !dropDown && optionsContainer}
            {isOpen && dropDown && <DropDown data-test="sr-picker_dropdown">{dropDown}</DropDown>}
          </DropContainer>
        </DropdownRelative>
      </OuterBody>
    )
  }
}

export const ICONS = {
  default: ({ customStyle }) => <Caret {...{ customStyle }} direction="down" />,
  fontAwesome: () => (
    <FontAwesomeIcon
      style={{
        position: 'absolute',
        right: '20px',
      }}
      icon={faChevronDown}
    />
  ),
}

DropdownArrowsPicker.defaultProps = {
  choices: [],
  name: '',
  useOutsideLabel: false,
  value: null,
  values: null,
  isValid: true,
  validator: null,
  customValidator: () => true,
  placeholder: '',
  displayOverride: null,
  adtlDisplayText: null,
  style: {},
  height: 42,
  optionsContainerStyle: {},
  borderAreaStyle: {},
  dropdownRelativeStyle: {},
  selectedItemDisplayStyle: {},
  optionBaseStyle: {},
  showNavigation: false,
  showCaret: true,
  IconCaret: ICONS.default,
  icon: null,
  dropDown: null,
  isLightTheme: false,
  isMultiSelect: false,
  dontShowSelectedItem: false,
  disabled: false,
  noHeader: false,
  wrapText: true,
  onMoveLeft: () => {},
  onMoveRight: () => {},
  onChangeHandler: () => {},
  id: '',
  ariaDescriptor: '',
}

DropdownArrowsPicker.propTypes = {
  testId: React.PropTypes.string,
  name: React.PropTypes.string.isRequired,
  useOutsideLabel: React.PropTypes.bool,
  isDropUp: React.PropTypes.bool,
  value: React.PropTypes.oneOfType([React.PropTypes.string, React.PropTypes.number]), // For single-select
  values: React.PropTypes.array, // For multi-select
  placeholder: React.PropTypes.string.isRequired,
  displayOverride: React.PropTypes.string,
  adtlDisplayText: React.PropTypes.string,
  choices: React.PropTypes.array.isRequired,
  style: React.PropTypes.object.isRequired,
  optionsContainerStyle: React.PropTypes.object.isRequired,
  borderAreaStyle: React.PropTypes.object.isRequired,
  selectedItemDisplayStyle: React.PropTypes.object,
  optionBaseStyle: React.PropTypes.object,
  dropdownRelativeStyle: React.PropTypes.object,
  showNavigation: React.PropTypes.bool.isRequired, // not applicable for multi-select
  icon: React.PropTypes.string,
  dropDown: React.PropTypes.object,
  dontShowSelectedItem: React.PropTypes.bool,
  isLightTheme: React.PropTypes.bool.isRequired,
  isMultiSelect: React.PropTypes.bool.isRequired,
  showCaret: React.PropTypes.bool,
  IconCaret: React.PropTypes.oneOf(Object.values(ICONS)),
  disabled: React.PropTypes.bool,
  validator: React.PropTypes.oneOf(_.values(ValidatorTypes)),
  isValid: React.PropTypes.bool,
  noHeader: React.PropTypes.bool.isRequired,
  wrapText: React.PropTypes.bool,

  customValidator: React.PropTypes.func.isRequired,
  onMoveLeft: React.PropTypes.func.isRequired,
  onMoveRight: React.PropTypes.func.isRequired,
  moveLeftValue: React.PropTypes.oneOfType([React.PropTypes.string, React.PropTypes.number]),
  moveRightValue: React.PropTypes.oneOfType([React.PropTypes.string, React.PropTypes.number]),
  onChangeHandler: React.PropTypes.func.isRequired,
  id: React.PropTypes.string,
  marginRight: React.PropTypes.string,
  ariaDescriptor: React.PropTypes.string,
  customStyle: React.PropTypes.object,
}

DropdownArrowsPicker = onClickOutside(DropdownArrowsPicker)
export default DropdownArrowsPicker
